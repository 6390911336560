<script>
import { workService } from "../../../helpers/backend/work.service"
import { dateMixin } from '../../../helpers/date'

export default {
  mixins: [dateMixin],
  data() {
    return {
      data: {
        id: null,
        user_id: null,
        username: null,
        vehicle_id: null,
        vehicle_name: null,
        start_odometer: 0,
        finish_odometer: 0,
        start_at: null,
        finish_at: null,
        work_route: [],
        is_approved_in: false,
        is_approved_out: false,
        product_ins: [],
        product_outs: [],
      },
      busy: false,
      show: true,
      showDismissibleAlert: false,
      error: "",
      active: false,
    }
  },
  created() {
    // this.fetchData()
  },
  computed: {
    isUpdateState() {
      return this.data.id != null
    },
    formStartAtTime: {
      get() {
        return this.getTime(this.data.start_at)
      },
      set(newValue) {
        this.data.start_at = this.setTime(this.data.start_at, newValue)
      }
    },
    formFinishAtTime: {
      get() {
        return this.getTime(this.data.finish_at)
      },
      set(newValue) {
        this.data.finish_at = this.setTime(this.data.finish_at, newValue)
      }
    }
  },
  methods: {
    
    reset() {
      // Reset our form values
      this.data.id = null
      this.data.user_id = null
      this.data.username = null
      this.data.vehicle_id = null
      this.data.vehicle_name = null
      this.data.start_odometer = 0
      this.data.finish_odometer = 0
      this.data.start_at = null
      this.data.finish_at = null
      this.data.work_route = []
      this.is_approved_in = false
      this.is_approved_out = false
      this.data.product_ins = []
      this.data.product_outs = []

      // Trick to reset/clear native browser form validation state
      this.show = false
      this.showDismissibleAlert = false
      this.error = ""
      this.$nextTick(() => {
        this.show = true
      })
    },
    onReset(event) {
      event.preventDefault()
      this.setActive(false)
      this.reset()
    },
    setActive(b, outside_triger) {
      this.active = b
      if (outside_triger) return
      this.$emit("active_change", b)
    },
    initData(id) {
      this.setActive(true)

      this.$nextTick(() => {
        this.reset()
        this.busy = true
        workService.getById(id).then(
          (data) => {
            if (data.work != null) {
              this.data.id = data.work.id
              this.data.user_id = data.work.user_id
              this.data.username = data.work.user.username
              this.data.vehicle_id = data.work.vehicle_id
              this.data.vehicle_name = data.work.vehicle.name
              this.data.start_odometer = data.work.start_odometer
              this.data.finish_odometer = data.work.finish_odometer
              this.data.start_at = data.work.start_at
              this.data.finish_at = data.work.finish_at
              this.data.work_route = data.work.work_route || []
              this.data.is_approved_in = data.work.is_approved_in
              this.data.is_approved_out = data.work.is_approved_out
            } else {
              this.showDismissibleAlert = true
              this.error =
                "Failed to get work to update. Error : work not found"
            }
            this.busy = false
          },
          (err) => {
            this.showDismissibleAlert = true
            this.error = "Failed to get work to update. Error : " + err
            this.busy = false
          }
        )

        workService.getProductMovement(id).then(
          (data) => {
            if (data.movement != null) {
              this.data.product_ins = data.movement.product_ins
              this.data.product_outs = data.movement.product_outs
            } else {
              this.showDismissibleAlert = true
              this.error =
                "Failed to get work to update. Error : work not found"
            }
            this.busy = false
          },
          (err) => {
            this.showDismissibleAlert = true
            this.error = "Failed to get work to update. Error : " + err
            this.busy = false
          }
        )
      })
    },
    fetchData() {
    },
    invokeCreate() {
      this.setActive(true)
    },
    onApproveOut(){
      workService.approveOut(this.data.id).then(
        (data) => {
          if (data != null) {
            this.initData(this.data.id)
            this.$emit("refresh")
            this.showDismissibleAlert = true
            this.error = ""
            this.reset()
          }
          this.busy = false
        },
        (error) => {
          this.showDismissibleAlert = true
          this.error =
            "Failed to Approve Work. Error : " + error
          this.busy = false
        }
      )

    },
    onApproveIn(){
      workService.approveIn(this.data.id).then(
        (data) => {
          if (data != null) {
            this.initData(this.data.id)
            this.$emit("refresh")
            this.showDismissibleAlert = true
            this.error = ""
            this.reset()
          }
          this.busy = false
        },
        (error) => {
          this.showDismissibleAlert = true
          this.error =
            "Failed to Approve Work. Error : " + error
          this.busy = false
        }
      )

    },
    onEditMove(type){
      console.log(this.data.id)
      this.$emit('onEditDetail', this.data.id, type)
    },
    
  },
  

}
</script>


<template>
  <div v-if="this.active">
    <b-alert
      :variant="error == '' ? 'success' : 'danger'"
      dismissible
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
    >
      <div v-if="error != ''">
        {{ error }}
      </div>
      <div v-else>Success perform Insert/Update action</div>
    </b-alert>
    <div class="card">
      <div class="card-body">
        <div>
          <b-form 
            v-if="show" ref="form">
            <b-overlay :show="busy" rounded="lg" opacity="0.6">
             
              <div
                v-if="data.work_route.length > 0"
                data-simplebar
                class="mb-2"
                style="max-height: 330px"
              >               
                <div>
                    <h4 class="font-size-16">
                      Username: {{ this.data.username }}
                    </h4>
                </div>
                <div>
                    <h4 class="font-size-13">
                      Vehicle: {{ this.data.vehicle_name }}
                    </h4>
                </div>
                <div>
                    <h4 class="font-size-13">
                      Start Odometer: {{ this.data.start_odometer }}
                    </h4>
                </div>
                <div>
                    <h4 class="font-size-13">
                      Finish Odometer: {{ this.data.finish_odometer }}
                    </h4>
                </div>
                
              </div>

              <div
                v-if="data.work_route.length > 0"
                data-simplebar
                class="mb-2"
                style="max-height: 330px"
              >
                <ul class="list-unstyled activity-wid">
                  <li
                    class="
                      activity-list
                      d-flex
                      justify-content-between
                      align-items-center
                    "
                    v-for="(data, index) in data.work_route"
                    :key="index"
                  >
                    <div>
                      <div class="activity-icon avatar-xs">
                        <span
                          class="
                            avatar-title
                            bg-soft-primary
                            text-primary
                            rounded-circle
                          "
                        >
                          <i class="ri-database-line" />
                        </span>
                      </div>
                      <div>
                        <div>
                          <h5 class="font-size-13">
                            {{ data.machine.name }}
                            <div
                              class="badge font-size-12 badge-success"
                              :class="
                                data.is_visited
                                  ? 'badge-success'
                                  : 'badge-warning'
                              "
                            >
                              <small class="text">
                                {{
                                  data.is_visited ? "Visited" : "Not Visited"
                                }}
                              </small>
                            </div>
                          </h5>
                        </div>

                        <div>
                          <p class="text-muted mb-0">
                            {{
                              data.is_visited
                                ? `Visited At : ${formatDate(data.visited_at)}`
                                : "Not visited yet"
                            }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>


              <div>
                <b-card-group deck>
                  <b-card
                    header=""
                    header-tag="header"
                    title="Warehouse -> Vehicle"
                  >
                    <template #header>
                      <h6 class="mb-0">
                        Movement Out
                        <div
                          class="badge font-size-12 badge-success"
                          :class="
                            data.is_approved_out
                              ? 'badge-success'
                              : 'badge-warning'
                          "
                        >
                          <small class="text">
                            {{
                              data.is_approved_out ? "Approved" : "Haven't Approved"
                            }}
                          </small>
                        </div>
                      </h6>
                    </template>
                    <b-card-text>
                      <table>
                        <tr v-for="(data, index) in this.data.product_outs" :key="index">
                          <td class="pr-2">{{ data.product.name }}</td>
                          <td class="pr-2"> {{ data.quantity }} pcs</td>
                          <td> ( {{ Math.ceil(data.quantity / data.product.qty_in_case) }} box )</td>
                        </tr>
                      </table>
                    </b-card-text>
                    <div v-if="!this.data.is_approved_out">
                      <b-button @click="onApproveOut()" variant="success" class="mr-3" v-b-tooltip.hover title="Approve">
                        <!-- Approve Movement Out -->
                        <i class="mdi mdi-check font-size-18"></i>
                      </b-button>
                      <b-button @click="onEditMove('out')" variant="primary" class="mr-3" v-b-tooltip.hover title="Edit">
                        <i class="mdi mdi-pencil font-size-18"></i>
                      </b-button>

                    </div>
                    <div v-else>
                      <!-- <b-button @click="onApproveOut()" variant="danger">Unapprove Movement Out</b-button> -->
                      <b-button @click="onApproveOut()" variant="danger" class="mr-3"  v-b-tooltip.hover title="Unapprove">
                        <i class="mdi mdi-close font-size-18"></i>
                      </b-button>
                    </div>
                  </b-card>

                  <b-card
                    header=""
                    title="Vehicle -> Warehouse" header-tag="header">
                    <template #header>
                      <h6 class="mb-0">
                        Movement In
                        <div
                          class="badge font-size-12 badge-success"
                          :class="
                            data.is_approved_in
                              ? 'badge-success'
                              : 'badge-warning'
                          "
                        >
                          <small class="text">
                            {{
                              data.is_approved_in ? "Approved" : "Haven't Approved"
                            }}
                          </small>
                        </div>  
                      </h6>
                    </template>
                    <b-card-text>
                      <table>
                        <tr v-for="(data, index) in this.data.product_ins" :key="index">
                          <td class="pr-2">{{ data.product.name }}</td>
                          <td class="pr-2"> {{ data.quantity }} pcs</td>
                          <td> ( {{ Math.ceil(data.quantity / data.product.qty_in_case) }} box )</td>
                        </tr>
                      </table>
                    </b-card-text>
                    <div v-if="!this.data.is_approved_in">
                      <!-- <b-button @click="onApproveIn()" variant="success">Approve Movement In</b-button> -->
                      <b-button @click="onApproveIn()" variant="success" class="mr-3" v-b-tooltip.hover title="Approve">
                        <!-- Approve Movement In -->
                        <i class="mdi mdi-check font-size-18"></i>
                      </b-button>
                      <b-button @click="onEditMove('in')" variant="primary" class="mr-3" v-b-tooltip.hover title="Edit">
                        <i class="mdi mdi-pencil font-size-18"></i>
                      </b-button>
                    </div>
                    <div v-else>
                      <!-- <b-button @click="onApproveIn()" variant="danger">Unapprove Movement In</b-button> -->
                      <b-button @click="onApproveIn()" variant="danger" class="mr-3"  v-b-tooltip.hover title="Unapprove">
                        <i class="mdi mdi-close font-size-18"></i>
                      </b-button>
                    </div>
                  </b-card>
                </b-card-group>
              </div>
            </b-overlay>
          </b-form>
        </div>
      </div>
    </div>
  </div>
</template>



