<script>
import { workService } from "../../../helpers/backend/work.service"
import { dateMixin } from '../../../helpers/date'

export default {
  mixins: [dateMixin],
  data() {
    return {
      form: {
        work_id: null,
        products: [],
        type: ''
      },
      fields: [
        { key: "product.id", sortable: true, label: "Product ID" },
        { key: "product.name", sortable: true, label: "Product Name" },
        { key: "quantity", sortable: true, label: "Quantity" },

      ],
      loading: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50],
      filter: null,
      filterOn: [],
      sortBy: "product.id",
      sortDesc: false,
      busy: false,
      show: true,
      showDismissibleAlert: false,
      error: "",
      active: false,
    }
  },
  created() {
  },
  mounted() {
  },
  computed: {
    isUpdateState() {
      return this.form.work_id != null
    },
    formStartAtTime: {
      get() {
        return this.getTime(this.form.start_at)
      },
      set(newValue) {
        this.form.start_at = this.setTime(this.form.start_at, newValue)
      }
    },
    formFinishAtTime: {
      get() {
        return this.getTime(this.form.finish_at)
      },
      set(newValue) {
        this.form.finish_at = this.setTime(this.form.finish_at, newValue)
      }
    }
  },
  methods: {
    onSubmit(event) {
      event.preventDefault()
      this.busy = true

      workService.updateMovementQty(this.form).then(
        (data) => {
          if (data != null) {
            this.reset()
            this.$emit("refresh")
            this.showDismissibleAlert = true
            this.error = ""
            this.setActive(false)
          }
          this.busy = false
        },
        (error) => {
          this.showDismissibleAlert = true
          this.error =
            "Failed to Update Movement Quantity. Error : " + error
          this.busy = false
        }
      )
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    reset() {
      this.form.products = []

      // Trick to reset/clear native browser form validation state
      this.show = false
      this.showDismissibleAlert = false
      this.error = ""
      this.$nextTick(() => {
        this.show = true
      })
    },
    onReset(event) {
      event.preventDefault()
      this.setActive(false)
      this.reset()
    },
    setActive(b, outside_triger) {
      this.active = b
      if (outside_triger) return
      this.$emit("active_change", b)
    },
    initData(id, type) {
      this.setActive(true)

      this.form.work_id = id
      this.form.type = type
      this.$nextTick(() => {
        this.reset()
        this.busy = true

        workService.getProductMovement(id).then(
          (data) => {
            if (data.movement != null) {
              if(this.form.type == 'in'){
                this.form.products = data.movement.product_ins
              }else if(this.form.type == 'out'){
                this.form.products = data.movement.product_outs
              }
            } else {
              this.showDismissibleAlert = true
              this.error =
                "Failed to get work to update. Error : work not found"
            }
            this.busy = false
            window.scrollTo(0, window.innerHeight);
          },
          (err) => {
            this.showDismissibleAlert = true
            this.error = "Failed to get work to update. Error : " + err
            this.busy = false
          }
        )
        
      })
    },
    fetchData() {
    },
    invokeCreate() {
      this.setActive(true)
    },
    
  },
  
}
</script>


<template>
  <div v-if="this.active">
    <b-alert
      :variant="error == '' ? 'success' : 'danger'"
      dismissible
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
    >
      <div v-if="error != ''">
        {{ error }}
      </div>
      <div v-else>Success perform Insert/Update action</div>
    </b-alert>
    <div class="card">
      <div class="card-body">
        <div>
          <h5>Edit movement {{ this.form.type }}</h5>
          <b-form @submit="onSubmit" @reset="onReset" v-if="show" ref="form">
            <b-overlay :show="busy" rounded="lg" opacity="0.6">
              <b-table
                :items="form.products"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
              >
                <template #cell(quantity)="row">
                  <b-form-input v-model.number="row.item.quantity" type="number" />
                </template>

              </b-table>
              <b-button class="mr-2" type="submit" variant="primary">
                Update qty in movement {{ this.form.type }}
              </b-button>

            </b-overlay>
          </b-form>
        </div>


      </div>
    </div>
  </div>
</template>



