<script>
import { workService } from "../../../helpers/backend/work.service"
import { userService } from "../../../helpers/backend/user.service"
import { vehicleService } from "../../../helpers/backend/vehicle.service"
import { dateMixin } from '../../../helpers/date'

export default {
  mixins: [dateMixin],
  data() {
    return {
      form: {
        id: null,
        user_id: null,
        vehicle_id: null,
        start_odometer: 0,
        finish_odometer: 0,
        start_at: null,
        finish_at: null,
        work_route: [],
      },
      user_options: [{ text: "Select One", value: null }],
      vehicle_options: [{ text: "Select One", value: null }],
      busy: false,
      show: true,
      showDismissibleAlert: false,
      error: "",
      active: false,
    }
  },
  created() {
    this.fetchData()
  },
  computed: {
    isUpdateState() {
      return this.form.id != null
    },
    formStartAtTime: {
      get() {
        return this.getTime(this.form.start_at)
      },
      set(newValue) {
        this.form.start_at = this.setTime(this.form.start_at, newValue)
      }
    },
    formFinishAtTime: {
      get() {
        return this.getTime(this.form.finish_at)
      },
      set(newValue) {
        this.form.finish_at = this.setTime(this.form.finish_at, newValue)
      }
    }
  },
  methods: {
    onSubmit(event) {
      event.preventDefault()
      this.busy = true
      workService.createOrUpdate(this.form).then(
        (data) => {
          if (data != null) {
            this.reset()
            this.$emit("refresh")
            this.showDismissibleAlert = true
            this.error = ""
            this.setActive(false)
          }
          this.busy = false
        },
        (error) => {
          this.showDismissibleAlert = true
          this.error =
            "Failed to Insert/Update Work. Error : " + error
          this.busy = false
        }
      )
    },
    reset() {
      // Reset our form values
      this.form.id = null
      this.form.user_id = null
      this.form.vehicle_id = null
      this.form.start_odometer = 0
      this.form.finish_odometer = 0
      this.form.start_at = null
      this.form.finish_at = null
      this.form.work_route = []

      // Trick to reset/clear native browser form validation state
      this.show = false
      this.showDismissibleAlert = false
      this.error = ""
      this.$nextTick(() => {
        this.show = true
      })
    },
    onReset(event) {
      event.preventDefault()
      this.setActive(false)
      this.reset()
    },
    setActive(b, outside_triger) {
      this.active = b
      if (outside_triger) return
      this.$emit("active_change", b)
    },
    initUpdate(id) {
      this.setActive(true)

      this.$nextTick(() => {
        this.reset()
        this.busy = true
        workService.getById(id).then(
          (data) => {
            if (data.work != null) {
              this.form.id = data.work.id
              this.form.user_id = data.work.user_id
              this.form.vehicle_id = data.work.vehicle_id
              this.form.start_odometer = data.work.start_odometer
              this.form.finish_odometer = data.work.finish_odometer
              this.form.start_at = data.work.start_at
              this.form.finish_at = data.work.finish_at
              this.form.work_route = data.work.work_route || []
            } else {
              this.showDismissibleAlert = true
              this.error =
                "Failed to get work type to update. Error : work not found"
            }
            this.busy = false
          },
          (err) => {
            this.showDismissibleAlert = true
            this.error = "Failed to get work type to update. Error : " + err
            this.busy = false
          }
        )
      })
    },
    fetchData() {
      userService.getAll().then(
        (data) => {
          this.user_options =
            this.user_options.concat(
              data.users.map(function (mType) {
                return { text: mType.name, value: mType.id }
              })
            )
        },
        (err) => {
          this.showDismissibleAlert = true
          this.error = "Failed to get users. Error : " + err
        }
      )

      vehicleService.getAll().then(
        (data) => {
          this.vehicle_options =
            this.vehicle_options.concat(
              data.vehicles.map(function (mType) {
                return { text: mType.name, value: mType.id }
              })
            )
        },
        (err) => {
          this.showDismissibleAlert = true
          this.error = "Failed to get vehicles. Error : " + err
        }
      )
    },
    invokeCreate() {
      this.setActive(true)
    },
  },
}
</script>


<template>
  <div v-if="this.active">
    <b-alert
      :variant="error == '' ? 'success' : 'danger'"
      dismissible
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
    >
      <div v-if="error != ''">
        {{ error }}
      </div>
      <div v-else>Success perform Insert/Update action</div>
    </b-alert>
    <div class="card">
      <div class="card-body">
        <div>
          <b-form @submit="onSubmit" @reset="onReset" v-if="show" ref="form">
            <b-overlay :show="busy" rounded="lg" opacity="0.6">
              <b-form-group
                id="input-group-user"
                label="User :"
                label-for="input-user"
              >
                <b-form-select
                  id="input-user"
                  v-model="form.user_id"
                  :options="user_options"
                  required
                ></b-form-select>
              </b-form-group>
              <b-form-group
                id="input-group-vehicle"
                label="Vehicle :"
                label-for="input-vehicle"
              >
                <b-form-select
                  id="input-vehicle"
                  v-model="form.vehicle_id"
                  :options="vehicle_options"
                  required
                ></b-form-select>
              </b-form-group>

              <b-row>
                <b-col>
                  <b-form-group
                    id="input-group-start-odometer"
                    label="Start Odometer :"
                    label-for="input-start-odometer"
                  >
                    <b-form-input
                      id="input-start-odometer"
                      v-model.number="form.start_odometer"
                      placeholder="Start Odometer"
                      type="number"
                    />
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    id="input-group-finish-odometer"
                    label="Finish Odometer :"
                    label-for="input-finish-odometer"
                  >
                    <b-form-input
                      id="input-finish-odometer"
                      v-model.number="form.finish_odometer"
                      placeholder="Finish Odometer"
                      type="number"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <b-form-group
                    id="input-group-start-at-date"
                    label="Started At :"
                    label-for="input-start-at-date"
                  >
                    <b-form-datepicker
                      id="input-start-at-date"
                      v-model="form.start_at"
                      placeholder="Start At"
                    />
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    id="input-group-start-at-time"
                    label="Time : "
                    label-for="input-start-at-time"
                  >
                    <b-form-timepicker
                      id="input-start-at-time"
                      v-model="formStartAtTime"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <b-form-group
                    id="input-group-finish-at-date"
                    label="Finished At :"
                    label-for="input-finish-at-date"
                  >
                    <b-form-datepicker
                      id="input-finish-at-date"
                      v-model="form.finish_at"
                      placeholder="Finish At"
                    />
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    id="input-group-finish-at-time"
                    label="Time :"
                    label-for="input-finish-at-time"
                  >
                    <b-form-timepicker
                      id="input-finish-at-time"
                      v-model="formFinishAtTime"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <div
                v-if="form.work_route.length > 0"
                data-simplebar
                class="mb-2"
                style="max-height: 330px"
              >
                <ul class="list-unstyled activity-wid">
                  <li
                    class="
                      activity-list
                      d-flex
                      justify-content-between
                      align-items-center
                    "
                    v-for="(data, index) in form.work_route"
                    :key="index"
                  >
                    <div>
                      <div class="activity-icon avatar-xs">
                        <span
                          class="
                            avatar-title
                            bg-soft-primary
                            text-primary
                            rounded-circle
                          "
                        >
                          <i class="ri-database-line" />
                        </span>
                      </div>
                      <div>
                        <div>
                          <h5 class="font-size-13">
                            {{ data.machine.name }}
                            <div
                              class="badge font-size-12 badge-success"
                              :class="
                                data.is_visited
                                  ? 'badge-success'
                                  : 'badge-warning'
                              "
                            >
                              <small class="text">
                                {{
                                  data.is_visited ? "Visited" : "Not Visited"
                                }}
                              </small>
                            </div>
                          </h5>
                        </div>

                        <div>
                          <p class="text-muted mb-0">
                            {{
                              data.is_visited
                                ? `Visited At : ${formatDate(data.visited_at)}`
                                : "Not visited yet"
                            }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>

              <b-button class="mr-2" type="submit" variant="primary">
                {{ isUpdateState ? "Update" : "Submit" }}
              </b-button>
              <b-button type="reset" variant="danger">Cancel</b-button>
            </b-overlay>
          </b-form>
        </div>
      </div>
    </div>
  </div>
  <!-- <div v-else>
    <b-form-group>
      <b-button variant="primary" @click="invokeCreate">Create new</b-button>
    </b-form-group>
  </div> -->
</template>



