<script>
import { workService } from '../../../helpers/backend/work.service'
import { dateMixin } from '../../../helpers/date'
/**
 * Work component
 */
export default {
  mixins: [dateMixin],
  data() {
    return {
      data: [],
      loading: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50],
      filter: null,
      filterOn: [],
      sortBy: "start_at",
      sortDesc: true,
      fields: [
        { key: "id", sortable: true, label: "id" },
        { key: "user", sortable: true, label: "User" },
        { key: "vehicle", sortable: true, label: "Vehicle" },
        { key: "start_odometer", sortable: true, label: "Start Odometer" },
        { key: "finish_odometer", sortable: true, label: "Finish Odometer" },
        { key: "start_at", sortable: true, label: "Start" },
        { key: "finish_at", sortable: true, label: "Finish" },
        { key: "action" }
      ],
      showDismissibleAlert: false,
      active: true,
    }
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.data.length
    }
  },
  created() {
    this.fetchData()
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.data.length
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    setActive(b) {
      this.active = b
    },
    fetchData() {
      this.error = null
      this.loading = true
      this.showDismissibleAlert = false
      //const fetchedId = this.$route.params.id
      workService.getAll().then(
        data => {
          this.data = data.works
          this.loading = false
        },
        err => {
          this.error = "Failed to fetch work data. Error : " + err + "  Dissmiss to refresh"
          this.loading = false
          this.showDismissibleAlert = true
        }
      )
    },
    onDelete(id) {
      if (confirm("Do you really want to delete this instance, this action can't be undone?")) {
        workService.deleteById(id).then(
          data => {
            this.fetchData()
            console.log(data)
          },
          err => {
            this.error = "Failed to delete work. Error : " + err
            this.showDismissibleAlert = true
          }
        )
      }
    },
    onUpdate(id) {
      this.$emit('onEdit',id)
    },
    onViewDetail(id) {
      this.$emit('onViewDetail',id)
    },
    invokeCreate() {
      this.$emit('onCreate')
    },

    formatDateUnixToUTC(date){
      if (date == 0){
        return ""
      }
      let dateUTC = new Date(0)
      dateUTC.setUTCSeconds(date)

      return dateUTC.toString().replace('T', ' ').split("G")[0] 
    },
  }
}
</script>

<template>
<div v-if="this.active">
  <!-- <div v-else> -->
    <b-form-group>
      <b-button variant="primary" @click="invokeCreate">Create new</b-button>
    </b-form-group>
  <!-- </div> -->
  <b-alert
      variant="danger"
      dismissible
      fade
      :show="showDismissibleAlert"
      @dismissed="fetchData"
    >
      {{ error }}
    </b-alert>
    <div class="card">
      <div class="card-body">
        <div class="row mt-4">
          <div class="col-sm-12 col-md-6">
            <div id="tickets-table_length" class="dataTables_length">
              <label class="d-inline-flex align-items-center">
                Show&nbsp;
                <b-form-select
                  v-model="perPage"
                  size="sm"
                  :options="pageOptions"
                ></b-form-select
                >&nbsp;entries
              </label>
            </div>
          </div>
          <!-- Search -->
          <div class="col-sm-12 col-md-6">
            <div
              id="tickets-table_filter"
              class="dataTables_filter text-md-right"
            >
              <label class="d-inline-flex align-items-center">
                Search:
                <b-form-input
                  v-model="filter"
                  type="search"
                  class="form-control form-control-sm ml-2"
                ></b-form-input>
              </label>
            </div>
          </div>
          <!-- End search -->
        </div>
        <!-- End search -->
      </div>
      <div class="table-responsive">
        <b-table
          :items="data"
          :fields="fields"
          responsive="sm"
          :per-page="perPage"
          :current-page="currentPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <template v-slot:cell(user)="row">
            {{ row.value.name }}
          </template>
          <template v-slot:cell(vehicle)="row">
            {{ row.value.name }}
          </template>
          <template v-slot:cell(start_at)="row">
            <!-- {{ formatDate(row.value) }} -->
            {{ formatDateUnixToUTC(row.value) }}
          </template>
          <template v-slot:cell(finish_at)="row">
            <!-- {{ formatDate(row.value) }} -->
            {{ formatDateUnixToUTC(row.value) }}
          </template>
          <template v-slot:cell(action)="row">
            <a
              @click="onViewDetail(row.item.id)"
              class="mr-3 text-primary"
              v-b-tooltip.hover
              data-toggle="tooltip"
              title="View Work Detail"
            >
              <i class="mdi mdi-eye font-size-18"></i>
            </a>
            <a
              @click="onUpdate(row.item.id)"
              class="mr-3 text-primary"
              v-b-tooltip.hover
              data-toggle="tooltip"
              title="Edit"
            >
              <i class="mdi mdi-pencil font-size-18"></i>
            </a>
            <a @click="onDelete(row.item.id)" class="text-danger" v-b-tooltip.hover title="Delete">
              <i class="mdi mdi-trash-can font-size-18"></i>
            </a>
          </template>
        </b-table>
      </div>
      <div class="row">
        <div class="col">
          <div class="dataTables_paginate paging_simple_numbers float-right">
            <ul class="pagination pagination-rounded mb-0">
              <!-- pagination -->
              <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>