import { authHeader } from './auth-header'
import axiosInstance from "./client"

export const workService = {
    getAll,
    createOrUpdate,
    getById,
    deleteById,
    approveIn,
    approveOut,
    getProductMovement,
    updateMovementQty,
};

function getById(machineTypeId) {
    const requestOptions = {
        url: '/api/v1/user/work/' + machineTypeId,
        method: 'GET',
        headers: authHeader()
    }
    return axiosInstance(requestOptions)
}

function getAll() {
    const requestOptions = {
        url: '/api/v1/user/work',
        method: 'GET',
        headers: authHeader()
    };
    return axiosInstance(requestOptions)
}


function createOrUpdate(jsonData) {

    let formData = new FormData();
    // jsonData['ID'] = jsonData['id']
    for ( var key in jsonData ) {
        if (jsonData[key] != null)
            formData.append(key, jsonData[key])
    }
    let rMethod = jsonData["id"] != null ? "PUT" : "POST"
    const requestOptions = {
        url: '/api/v1/user/work',
        method: rMethod,
        data: formData,
        headers: authHeader()
    }

    return axiosInstance(requestOptions)
}


function deleteById(id) {
    const requestOptions = {
        url: `/api/v1/user/work/` + id,
        method: 'DELETE',
        headers: authHeader()
    }
    return axiosInstance(requestOptions)
}

function approveIn(id) {
    const requestOptions = {
        url: `/api/v1/user/work/approve_in/` + id,
        method: 'PATCH',
        headers: authHeader()
    };
    return axiosInstance(requestOptions);
}

function approveOut(id) {
    const requestOptions = {
        url: `/api/v1/user/work/approve_out/` + id,
        method: 'PATCH',
        headers: authHeader()
    };
    return axiosInstance(requestOptions);
}

function getProductMovement(workId) {
    const requestOptions = {
        url : '/api/v1/user/movement/in_and_out/' + workId,
        method: 'GET',
        headers: authHeader()
    };
    return axiosInstance(requestOptions)
}

function updateMovementQty(jsonData) {
    const requestOptions = {
        url: '/api/v1/user/movement/work',
        method: "PUT",
        headers: { 'Content-Type': 'application/json' },
        data: JSON.stringify(jsonData)
    }

    return axiosInstance(requestOptions)
}