<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

import List from './list'
import Form from './form'
import ViewDetail from './detail_work'
import EditDetail from './edit_detail_work'

/**
 * Dashboard component
 */
export default {
  components: {
    Layout,
    PageHeader,
    List,
    Form,
    ViewDetail,
    EditDetail,
  },
  data() {
    return {
      title: "Work",
      items: [
        {
          text: "VMCore"
        },
        {
          text: "Work",
          active: true
        }
      ]
    };
  },
  methods :{
    onActiveChange(b) {
      this.$refs.list.setActive(!b);
    },
    onRefresh(){
      this.$refs.list.fetchData()
    },
    onEdit(id){
      this.$refs.form.initUpdate(id)
    },
    onViewDetail(id){
      this.$refs.view_detail.initData(id)
    },
    onEditDetail(id, type){
      this.$refs.edit_detail.initData(id, type)
    },
    onCreate(){
      this.$refs.form.invokeCreate()
    },
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xl-12">
        <Form ref="form" @refresh="onRefresh" @active_change="onActiveChange"/>
        <ViewDetail ref="view_detail" @refresh="onRefresh" @active_change="onActiveChange" @onEditDetail="onEditDetail"/>
        <EditDetail ref="edit_detail" @refresh="onRefresh" @active_change="onActiveChange"/>
        <List ref="list" @onEdit="onEdit" @onViewDetail="onViewDetail" @onCreate="onCreate"/>
      </div>
      
    </div>
  </Layout>
</template>